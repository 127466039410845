import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import PortfolioOverview from './components/PortfolioOverview';
import PerformanceChart from './components/PerformanceChart';
import CapitalInvested from './components/CapitalInvested';
import HoldingsTable from './components/HoldingsTable';
import Login from './components/Login';
import Footer from './components/Footer';

const AppContainer = styled.div`
  display: flex;
  min-height: 100vh;
  font-family: 'Inter', sans-serif;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const MainContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const DashboardContent = styled.div`
  flex-grow: 1;
  background-color: #f0f2f5;
  padding: 20px;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const DashboardLayout = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const LeftColumn = styled.div`
  flex: 3;
`;

const RightColumn = styled.div`
  flex: 1;

  @media (max-width: 768px) {
    margin-top: 20px;
  }
`;

const FullWidthSection = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

function App() {
  const [holdings, setHoldings] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const investorId = '123'; // This would come from your authentication system

  useEffect(() => {
    if (isLoggedIn) {
      console.log('Fetching data for investor:', investorId);
      fetch(`http://162.243.185.163/api/investor-data/${investorId}`)
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          console.log('Received data:', data);
          const formattedData = data.map(holding => ({
            ...holding,
            investment: parseFloat(holding.investment),
            nav: parseFloat(holding.nav),
            moic: parseFloat(holding.moic)
          }));
          setHoldings(formattedData);
        })
        .catch(error => console.error('Error fetching investor data:', error));
    }
  }, [isLoggedIn, investorId]);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  console.log('Current holdings:', holdings);

  if (!isLoggedIn) {
    return <Login onLogin={() => setIsLoggedIn(true)} />;
  }

  return (
    <AppContainer>
      {!isMobile && <Sidebar />}
      <MainContent>
        <Header />
        <DashboardContent>
          <FullWidthSection>
            <PortfolioOverview holdings={holdings} />
          </FullWidthSection>
          <DashboardLayout>
            <LeftColumn>
              <PerformanceChart holdings={holdings} />
            </LeftColumn>
            <RightColumn>
              <CapitalInvested holdings={holdings} />
            </RightColumn>
          </DashboardLayout>
          <FullWidthSection>
            <HoldingsTable holdings={holdings} setHoldings={setHoldings} />
          </FullWidthSection>
        </DashboardContent>
        <Footer />
      </MainContent>
    </AppContainer>
  );
}

export default App;
