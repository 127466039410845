import React, { useState } from 'react';
import styled from 'styled-components';
import logo from '../armyncaplogo.png';
import logoSmall from '../logosmall.png';

const LoginContainer = styled.div`
  display: flex;
  height: 100vh;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const LeftPanel = styled.div`
  flex: 1;
  background-color: #0B1521;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 80px;
  @media (max-width: 768px) {
    padding: 20px;
    align-items: center;
  }
`;

const RightPanel = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F3F4F6;
  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const LoginBox = styled.div`
  background-color: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 320px;
  @media (max-width: 768px) {
    width: 100%;
    padding: 20px;
  }
`;

const Title = styled.h1`
  font-family: 'Inter', sans-serif;
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 10px;
  text-align: left;
  @media (max-width: 768px) {
    text-align: center;
  }
`;

const Subtitle = styled.p`
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  @media (max-width: 768px) {
    text-align: center;
  }
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #E5E7EB;
  border-radius: 4px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  box-sizing: border-box;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #2563EB;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 14px;
  margin-top: 15px;
  box-sizing: border-box;
`;

const Logo = styled.img`
  width: 240px;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    width: 180px;
  }
`;

const LogoSmall = styled.img`
  width: 70px;
  margin-bottom: 10px;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const LoginText = styled.h2`
  font-family: 'Inter', sans-serif;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #1F2937;
  text-align: center;
`;

function Login({ onLogin }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (username === 'test' && password === 'test') {
      onLogin();
    } else {
      alert('Invalid credentials');
    }
  };

  return (
    <LoginContainer>
      <LeftPanel>
        <Logo src={logo} alt="Armyn Capital Logo" />
        <Title>Access the Armyn Capital LP Dashboard</Title>
        <Subtitle>Sign in using the credentials provided.</Subtitle>
      </LeftPanel>
      <RightPanel>
        <LoginBox>
          <LogoSmall src={logoSmall} alt="Armyn Capital Small Logo" />
          <LoginText>Login</LoginText>
          <Form onSubmit={handleSubmit}>
            <Input
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <Input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button type="submit">Login</Button>
          </Form>
        </LoginBox>
      </RightPanel>
    </LoginContainer>
  );
}

export default Login;
