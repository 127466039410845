import React from 'react';
import styled from 'styled-components';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const Container = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin-bottom: 20px;
  height: 100%;
`;

const Title = styled.h2`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #1e2837;
`;

const InvestmentItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 8px;
`;

const InvestmentInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const InvestmentName = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: #1e2837;
  margin-bottom: 5px;
`;

const InvestmentValue = styled.span`
  font-size: 14px;
  color: #1e2837;
`;

const ProgressContainer = styled.div`
  width: 80px;
  height: 80px;
`;

function CapitalInvested({ holdings }) {
  const totalInvested = holdings.reduce((sum, holding) => sum + holding.investment, 0);
  
  const sectors = holdings.reduce((acc, holding) => {
    if (acc[holding.sector]) {
      acc[holding.sector] += holding.investment;
    } else {
      acc[holding.sector] = holding.investment;
    }
    return acc;
  }, {});

  const sectorPercentages = Object.entries(sectors).map(([sector, amount]) => ({
    sector,
    amount,
    percentage: (amount / totalInvested) * 100
  })).sort((a, b) => b.percentage - a.percentage);

  return (
    <Container>
      <Title>Industry Breakdown</Title>
      {sectorPercentages.map(({ sector, amount, percentage }, index) => (
        <InvestmentItem key={sector}>
          <InvestmentInfo>
            <InvestmentName>{sector}</InvestmentName>
            <InvestmentValue>${amount.toLocaleString()}</InvestmentValue>
          </InvestmentInfo>
          <ProgressContainer>
            <CircularProgressbar
              value={percentage}
              text={`${percentage.toFixed(1)}%`}
              styles={buildStyles({
                textColor: "#66ccff",
                pathColor: "#66ccff",
                trailColor: "#d6d6d6",
              })}
            />
          </ProgressContainer>
        </InvestmentItem>
      ))}
    </Container>
  );
}

export default CapitalInvested;
