import React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import styled from 'styled-components';

const ChartContainer = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin-bottom: 20px;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
  }
`;

const Title = styled.h2`
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  color: #1e2837;

  @media (max-width: 768px) {
    font-size: 14px;
    margin-bottom: 10px;
  }
`;

const SeeDetails = styled.a`
  color: #3498db;
  font-size: 14px;
  text-decoration: none;
  font-weight: 500;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

function PerformanceChart({ holdings }) {
  const sortedHoldings = [...holdings].sort((a, b) => new Date(a.date) - new Date(b.date));

  const data = sortedHoldings.reduce((acc, holding, index) => {
    const previousInvested = index > 0 ? acc[index - 1].invested : 0;
    const previousUnrealized = index > 0 ? acc[index - 1].unrealized : 0;
    const totalInvested = previousInvested + holding.investment;
    const totalUnrealized = previousUnrealized + holding.nav;

    acc.push({
      date: holding.date,
      invested: totalInvested,
      unrealized: totalUnrealized,
    });

    return acc;
  }, []);

  const formatYAxis = (value) => {
    return `$${(value / 1000000).toFixed(1)}M`;
  };

  const formatTooltipValue = (value) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
  };

  return (
    <ChartContainer>
      <Header>
        <Title>PORTFOLIO PERFORMANCE</Title>
        <SeeDetails>See Details</SeeDetails>
      </Header>
      <ResponsiveContainer width="100%" height={300}>
        <AreaChart data={data}>
          <defs>
            <linearGradient id="colorInvested" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#0033ff" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#0033ff" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorUnrealized" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#66ccff" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#66ccff" stopOpacity={0} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" stroke="#e0e0e0" />
          <XAxis dataKey="date" stroke="#777" />
          <YAxis stroke="#777" tickFormatter={formatYAxis} />
          <Tooltip formatter={formatTooltipValue} />
          <Area type="monotone" dataKey="invested" stroke="#0033ff" fill="url(#colorInvested)" />
          <Area type="monotone" dataKey="unrealized" stroke="#66ccff" fill="url(#colorUnrealized)" />
        </AreaChart>
      </ResponsiveContainer>
    </ChartContainer>
  );
}

export default PerformanceChart;
