import React from 'react';
import styled from 'styled-components';

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #ffffff;
  border-bottom: 1px solid #e0e0e0;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Title = styled.h1`
  margin: 0;
  padding-left: 20px;
  font-size: 24px;

  @media (max-width: 768px) {
    padding-left: 0;
    font-size: 20px;
  }
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
    justify-content: space-between;
    margin-top: 10px;
  }
`;

function Header() {
  return (
    <HeaderContainer>
      <Title>Dashboard</Title>
      <RightSection>
        <div>PAPAPAPPAPAPAPA</div>
      </RightSection>
    </HeaderContainer>
  );
}

export default Header;

